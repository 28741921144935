<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="col-md-6 d-flex align-items-stretch mb-3">
            <div class="row flex-grow">
                <div class="col-12 p-0">
                    <div class="text-left">
                        <b-btn v-on:click="resetForm" variant="primary">
                            {{ $t('Add Custom Field') }}
                        </b-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('Custom Fields') }}</h4>
                            <div class="header-buttons">
                                <button class="btn btn-primary" @click="showCustomArchieved">{{ $t('Archived') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                :rows="rows"
                                :columns="columns"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :row-style-class="checkDeleted"
                                :line-numbers="true">
                                <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'action'" class="text-nowrap">
                                    <b-button size="sm" @click="editCustomField(props.row.id)" class="mr-1 btn-info text-white">
                                        {{ $t('edit-btn') }}
                                    </b-button>
                                    <b-button size="sm" @click="deleteCustomField(props.row.id)"
                                        class="mr-1 btn-danger text-white">
                                        {{ $t('delete-btn') }}
                                    </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="this.is_pro_sync === 1 && this.version_id === 2">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('General Comments') }}</h4>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                            mode="remote"
                            @on-page-change="onCommentsPageChange"
                            @on-sort-change="onCommentsSortChange"
                            @on-column-filter="onCommentsColumnFilter"
                            @on-per-page-change="onCommentsPerPageChange"
                            :totalRows="commentsTotalRecords"
                            :isLoading.sync="isCommentsLoading"
                            :pagination-options="{ enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200] }"
                            :rows="commentsRows"
                            :columns="commentsColumns"
                            @on-search="commentSearchFn"
                            :search-options="{enabled: true,commentSearchFn: commentSearchFn}"
                            :line-numbers="true"
                            >
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modalmd" ref="vuemodal" :title="title" size="md" hide-footer>
            <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
               <!-- div class="row">
                    <div class="col-md-12">
                        <b-form-group :label="$t('Survey Name*')" label-for="name">
                            <b-form-select class="mt-1" v-model="form.survey_id" aria-describedby="input-1-live-feedback" :options="surveys" value-field="id" text-field="name">
                                <b-form-select-option v-if="surveys.length === 0" :disabled = true>{{ $t('No Survey Found') }}</b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-1-live-feedback">
                                {{ $t("field-required") }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div> -->
                <div class="row mt-2">
                    <div class="col-md-6">
                    <b-form-group :label="$t('question*')" label-for="question">
                        <b-form-input type="text" id="question" name="question" autocomplete="new-password" :placeholder="$t('Question?')" v-model="$v.form.question.$model" :state="$v.form.question.$dirty ? !$v.form.question.$error : null" aria-describedby="input-1-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group id="answer" :label="$t('answer')" label-for="answer">
                            <b-form-input id="answer" type="text" :placeholder="$t('Enter Possible Responses separted by commas')" v-model="form.answer"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                    <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                    <span v-else>{{ $t('update-btn') }}</span>
                </b-button>
            </form>
        </b-modal>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment'
    import i18n from '@/i18n';

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Spotters',
        mixins: [validationMixin],
        data() {
            return {
                isDisabled: false,
                custom_fields: [],
                title: i18n.t('Add Custom Field'),
                version_id: null,
                is_pro_sync: '',
                form: {
                    id: "",
                    question: "",
                    answer: "",
                    survey_id: "",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'asc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Question Field',
                        field: 'question',
                        filterable: true
                    },
                    {
                        label: 'Expected Answer',
                        field: 'answers',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        filterable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        filterable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                surveys: [],
                  // Data properties for comments
                isCommentsLoading: false,
                commentsRows: [],
                commentsTotalRecords: 0,
                commentsServerParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [
                    {
                        field: 'id',
                        type: 'asc'
                    }
                    ],
                    page: 1,
                    perPage: 10
                },
                commentsColumns: [
                    {
                        label: 'Project Name',
                        field: 'project_name',
                        filterable: true
                    },
                    {
                        label: 'User Name',
                        field: 'user_name',
                        filterable: true
                    },
                    {
                        label: 'Comment',
                        field: 'comment',
                        filterable: true
                    },
                    {
                        label: 'Comment Date Time',
                        field: 'commentDateTime',
                        filterable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        filterable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        filterable: true,
                        formatFn: this.formatDate
                    },
                ]
            };
        },
        validations: {
            form: {
                id: {},
                question: {
                    required,
                },
            }
        },
        methods: {
            deleteCustomField(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.deleteCustomField(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record has been deleted.');
                            },
                            err => {})
                    }
                })
            },
            showCustomArchieved() {
                this.$router.push("/custom/fields/archived", () => {});
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                if (this.form.question != '') {
                    this.isDisabled = true;
                    const data = {
                        ...this.form,
                    }
                    API.addEditCustomField(
                        data,
                        data => {
                            this.$refs["vuemodal"].$refs['close-button'].click();
                            if (data.status === 200) {
                                this.$snotify.success(data.message);
                            } else {
                                this.$snotify.error(data.message);
                            }
                            this.loadItems();
                            this.isDisabled = false;
                            this.form.question = '';
                            this.form.id = '';
                            this.form.answer = '';
                            this.form.survey_id = '';
                            this.$v.form.$reset();
    
    
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
    
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.isDisabled = false;
                }
            },
            resetForm() {
                this.title = i18n.t('Add Custom Field');
                this.form.question = '';
                this.form.answer = '';
                this.form.id = '';
                this.form.survey_id = '';
                this.$v.form.$reset();
                this.$root.$emit("bv::show::modal", "modalmd");
            },
            // editSpotter(e) {
            //     this.$router.push({name: 'editSpotter', params: {id: e}});
            // },
            editCustomField(e) {
                this.$v.form.$reset();
                this.title = i18n.t('Edit Custom Field');
                this.$root.$emit("bv::show::modal", "modalmd");
                API.getCustomField(
                    e, data => {
                        this.form.question = data.question;
                        this.form.id = data.id;
                        this.form.survey_id = data.survey_id;
                        let text1 = [];
                        if (data.custom_field_responses.length > 0) {
                            data.custom_field_responses.forEach((values, index) => {
                                text1.push(values.answer);
                            });
                            this.form.answer = text1.toString();
                        } else {
                            this.form.answer = '';
                        }
                        // this.form.answer = data.answer;
                    },
                    err => {}
                )
            },
            loadItems() {
                this.isLoading = true;
                API.getCustomFields(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
    
                            };
                            this.custom_fields.push(data);
                        });
                    },
                    err => {
                    }
                )
            },
            loadComments() {
                this.isCommentsLoading = true;
                API.getComments(this.commentsServerParams,
                    data => {
                        this.isCommentsLoading = false;
                        this.commentsTotalRecords = data.totalRecords;
                        this.commentsRows = data.data;
                    },
                    err => {
                        this.isCommentsLoading = false;
                    }
                )
            },
            onCommentsPageChange(params) {
                this.updateCommentsParams({ page: params.currentPage });
                this.loadComments();
            },
            onCommentsPerPageChange(params) {
                this.updateCommentsParams({ perPage: params.currentPerPage });
                this.loadComments();
            },
            onCommentsSortChange(params) {
                this.updateCommentsParams({
                    sort: [{
                    type: params[0].type,
                    field: params[0].field,
                    }],
                });
                this.loadComments();
            },
            onCommentsColumnFilter(params) {
                this.updateCommentsParams(params);
                this.loadComments();
            },
            updateCommentsParams(newProps) {
                this.commentsServerParams = Object.assign({}, this.commentsServerParams, newProps);
            },
            commentSearchFn(params) {
                this.updateCommentsParams({searchTerm: params});
                this.loadComments();
                this.isCommentsLoading = false;
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            status(status) {
                if (status === 'A') {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            checkDeleted(row) {
                return row.deleted_at !== null ? 'red-text' : '';
            },
        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
            this.loadComments();
            // this.getAllSurveys();
        }
    }
</script>

<style lang="scss">
    .red-text .vgt-left-align {
        color: red !important;
    }
    .btn-disabled {
        width: 100px;
    }
    .btn-restore {
        width: 70px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>

